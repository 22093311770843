/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        if($('#hero_starter').length) {
          var navbarOffset =  $('#hero_starter').offset().top + $('#hero_starter').height() + 55;
          $('.top-menu').affix({
            offset: { top: navbarOffset}
          });
        }
        if($('.order_summary_widget').length) {
          $('.order_summary_widget').affix({
            offset: { top: $('.sidebar-item-cnt').offset().top-55 }
          });
        }

        //jQuery to collapse the navbar on scroll
        // $(window).scroll(function() {
        //   if($('#hero_starter').length) {
        //     if ($(".top-menu .navigation").offset().top > navbarOffset) {
        //         $(".top-menu .navigation").addClass("top-nav-collapse");
        //         $('#product').css('margin-top','55px');
        //     } else {
        //         $(".top-menu .navigation").removeClass("top-nav-collapse");
        //         $('#product').css('margin-top','0px');
        //     }
        //   }

        // });
        // $('body').scrollspy({
        //    offset: $(window).height() * 0.2
        // });

        //jQuery for page scrolling feature - requires jQuery Easing plugin
          $('.top-menu .navigation a').bind('click', function(event) {
              var $anchor = $(this);
              $('html, body').stop().animate({
                  scrollTop: $($anchor.attr('href')).offset().top - 100
              }, 1500, 'easeInOutExpo');
              event.preventDefault();
          });

          $('a.btn-selection').not('.choose_plan').click(function(e){
            //e.preventDefault;
            $(this).removeAttr('href');

              if (!$(this).hasClass('disabled')) {
                $(this).toggleClass('selected');

                if ($(this).hasClass('selected')) {
                  $(this).text('Selected');
                } else {
                  $(this).text('Select');
                }
              }

          });


          $('a.btn-selection').not('.choose_plan').click(function(e){
            //e.preventDefault;
            //console.log($(this));
              $(this).closest('.products').find('a.btn-selection').removeClass('selected').text('Select');
              $(this).addClass('selected');
              $(this).text('Selected');
              $(this).closest('.products').find('.upload-file').addClass('in');

          });
          $('#digital_upsell .plan a.choose_plan').click(function(e){
            $(this).removeAttr('href');
            $(this).parent().parent().parent().find('.plan a.btn-selection').removeClass('selected').text('Choose Plan');
            $(this).addClass('selected').text('Selected Plan');
            console.log('ebbe vagyunk');

          });

          $('.upload-selection form input').click(function(){
              if ($(this).is(':checked')) {
                if ($(this).attr('value') === 'no') {

                  $(this).parent().parent().parent().find('.fileupload').addClass('hidden');
                } else {
                  $(this).parent().parent().parent().find('.fileupload').removeClass('hidden');
                }
              }  else {
                if ($(this).attr('value') === 'no') {
                  $(this).parent().parent().parent().find('.fileupload').addClass('hidden');
                } else {
                  $(this).parent().parent().parent().find('.fileupload').removeClass('hidden');
                }

              }
          });
          $('.add_new').click(function(){
            $('.change-address .modal-footer').removeClass('hidden');
          });
          $('.locations .location').click(function(){
            $('.locations .location').removeClass('selected');
            $(this).addClass('selected');
          });
          // new home 2018
          $('.new-home-2018 .eddm-target .card .card-content').matchHeight();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
